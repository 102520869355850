
import { Options, Vue } from 'vue-class-component';
import { kioskModule } from './store/kiosk.vuex-module';
import { LanguagesEntity } from './common/types/client.type';

@Options({
	name: 'App',
	components: {},
})
export default class App extends Vue {
	flagOpen = false;
	selectedFlag: LanguagesEntity | undefined;
	get lang() {
		let urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('ljs');
	}
	get flags() {
		return (kioskModule._client?.Languages ?? []).filter((i) => this.flag?.LanguageKey != i.LanguageKey);
	}
	get flag() {
		if (this.lang) return kioskModule._client?.Languages?.find((i) => i.Description.split('-')[0] == this.lang);
		return this.selectedFlag ?? kioskModule._client?.Languages[0];
	}
	set flag(newValue: LanguagesEntity | undefined) {
		this.selectedFlag = newValue;
		let code = newValue?.Description.split('-')[0];
		let loc = `${(window as any).location}`.split('?')[0];
		if (newValue && code) (window as any).location = loc + '?ljs=' + code;
		//If we dont want to reload the entire page, send this event to switch language. Will need to update code to render flag
		//document.dispatchEvent(new CustomEvent('setLanguage', { detail: lang }))
	}

	getFlagEmoji(flag: LanguagesEntity | undefined): string {
		if (!flag) return 'X';
		const countryCode = flag.Description.split('-')[1];
		const codePoints = countryCode
			.toUpperCase()
			.split('')
			.map((char) => 127397 + char.charCodeAt(0));
		return String.fromCodePoint(...codePoints);
	}
	getCountryFlag(flag: LanguagesEntity | undefined): string {
		if (!flag) return 'X';
		const countryCode = flag.Description.split('-')[1];
		return 'https://book.singenuity.com/flags-master/flags/flags-iso/flat/64/' + countryCode + '.png';
	}

	//this stopped working, assuming because localize changed the html structure of their widget.
	//switching to a custom one that does it manually by appending '?ljs=es' to the url
	filterLanguages() {
		const supportedLangs = kioskModule.client?.Languages.map((l) => l.LanguageKey);
		if (supportedLangs?.length) {
			const langToRemove: HTMLAnchorElement[] = [];
			const ljs = document.getElementById('localize-widget-langs');
			const trLangOpts = ljs?.getElementsByTagName('a');
			for (let langLink of trLangOpts ?? []) {
				const key = langLink.outerHTML.slice(34, langLink.outerHTML.indexOf('>') - 4);
				const langKey = kioskModule.langLookup(key);
				if (!supportedLangs.includes(langKey)) {
					langToRemove.push(langLink);
				}
			}

			for (let lang of langToRemove) {
				lang.remove();
			}
		}
	}
}
