import { apiService } from './services/api.service';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Logger from './plugins/logger.plugin';
import LoaderDisplay from '@/common/components/loader-display.component.vue';
import Currency from '@/common/components/currency.component.vue';
import ProgressButton from '@/common/components/progress-button.component.vue';
import NotifyBox from '@/common/components/notify-box.component.vue';

import ResizeTableDirective from '@/directives/resizable-table.directive';
import FocusSelectDirective from '@/directives/input-focus-select.directive';

if (process.env.VUE_APP_KIOSK_VERSION && process.env.VUE_APP_BUILD) console.info(`Singenuity Kiosk - Build v${process.env.VUE_APP_KIOSK_VERSION}.${process.env.VUE_APP_BUILD}`);

// set config variables
let configUrl = (window as any).__CONFIG_URL as string;
if (!configUrl) configUrl = process.env.VUE_APP_DEV_CONFIG_URL as string; // local dev config URL
fetch(`${configUrl}/api/configuration`)
	.then((config) => {
		return config.json();
	})
	.then((config) => {
		(window as any).config = config;
		apiService.init();
		const app = createApp(App);
		app.directive('focus-select', FocusSelectDirective);
		app.directive('resize-table', ResizeTableDirective);

		app.component('si-currency', Currency);

		app.component('loader-display', LoaderDisplay);
		app.component('currency', Currency);
		app.component('progress-button', ProgressButton);
		app.component('notify-box', NotifyBox);
		app.use(Logger).use(store).use(router).mount('#app');
	});
