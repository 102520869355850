
import { Options, Vue } from 'vue-class-component';
import SmallLoader from '@/common/components/small-loader.component.vue';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ProgressButton',
	components: { SmallLoader },
	emits: ['onClick'],
})
export default class ProgressButton extends Vue {
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: false }) showLoader!: boolean;
}
