import { OrderMerchandiseGiftCard } from '@/common/models/OrderMerchandiseGiftCard.model';
import { Order } from '@/common/models/Order.model';
import { OrderPromoCode } from '@/common/models/OrderPromoCode.model';
import { PromoCodeModel } from '@/common/models/PromoCode.model';
import { clientModule } from '@/modules/client/client.vuex-module';
import { uuid } from '@/proxies/uuid.proxy';
import { apiService } from '../../../../services/api.service';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { OrderPayment } from '@/common/models/OrderPayment.model';
import { validEmail } from '@/common/utilities/Validators.utility';
import { getCustomFieldResponseString } from '@/common/utilities/customFieldResponse.utility';
import { RegisterPayment } from '@/common/models/RegisterPayment.model';

export default class ActiveOrder {
	error: string | null = null;
	isLoading = false;

	constructor(private _order: Order) {
		console.log('[Order]', _order);
		if (this.paymentDue) console.log('[orderPayment]', this.paymentDue);
		//add activity names
		_order.Individual.forEach((p) => {
			p.Activities.forEach((a) => {
				a.SelectedTimes.forEach((t) => {
					t.Appointment.ActivityName = a.Name;
				});
			});
		});

		// modify Activities to have packageKeys
		_order.Packages.forEach((p) => {
			p.Activities.forEach((a) => {
				if (a.SelectedTimes[0]) {
					a.AddOns = a.SelectedTimes[0].AddOns;
				}
				a.SelectedTimes.forEach((t) => {
					t.Appointment.ActivityName = a.Name;
				});
				a.PackageKey = p.PackageKey;
				a.OrderPackageKey = p.OrderPackageKey;
			});
		});
		// development auto fill
		// if (this.isNew && !window.location.host.includes('singenuity')) {
		// 	const dev = {
		// 		OrderKey: this._order.OrderKey,
		// 		FirstName: 'Reese',
		// 		LastName: 'Peltier',
		// 		PhoneSubscriberNumber: '4567890',
		// 		PhoneAreaCode: '123',
		// 		PhoneCountryKey: 196,
		// 		Email: 'reese@singenuity.com',
		// 	};
		// 	this._order.Customer = { ...this._order.Customer, ...dev };
		// 	this.saveCustomerToOrder();
		// }
	}
	get hasPromoCodes(): boolean {
		return !!this._order.OrderPromoCodes.length;
	}
	get hasPaidPayment(): boolean {
		return !!this._order.OrderPayments.filter((p) => p.Paid).length;
	}
	get hasTickets(): boolean {
		return !!this._order.HasTickets;
	}
	get requirements() {
		return this._order.Requirements;
	}
	get balance() {
		return this._order.Balance;
	}

	get paymentDue(): OrderPayment | null {
		return this._order?.OrderPayments.find((p) => p.Paid == false) ?? null;
	}
	get paid(): boolean {
		if (this.isFree) {
			return true;
		}
		return this._order?.OrderPayments.reduce((previousValue: boolean, currentValue: OrderPayment) => {
			return previousValue && currentValue.Paid;
		}, true);
	}
	get agreed() {
		return this._order.Customer.Agreed;
	}
	set agreed(newValue: boolean) {
		this._order.Customer.Agreed = newValue;
	}
	get customFields() {
		return this._order.Customer.CustomFields;
	}
	get contactInfoValid() {
		return this._order.Customer.FirstName && this._order.Customer.LastName && validEmail(this._order.Customer.Email);
	}
	get additionalInfoValid() {
		return this._order.Customer.Agreed && (this._order.Customer.ClientLocationHeardAboutUsKey || this._order.Customer.HeardAboutUsOther) && this.customFields.every((f) => !!getCustomFieldResponseString(f) || !f.IsRequired);
	}
	get customerInfo() {
		return this._order.Customer;
	}

	get discountTotal() {
		return this._order.DiscountTotal;
	}

	get fees() {
		return this._order.OrderFees;
	}
	get ticketUUID() {
		return this._order.TicketUUID;
	}
	get items() {
		return [...this._order.Individual, ...this._order.Packages];
	}
	get activities(): EntityHierarchy[] {
		return [...this._order.Individual.map((i) => i.Activities).flat(), ...this._order.Packages.map((i) => i.Activities).flat()];
	}

	get isNew() {
		return this._order.IsNew;
	}
	get isFree() {
		return this._order.IsFree;
	}
	get orderEntityKeys() {
		return this._order.Individual.map((i) => i.OrderEntityKey);
	}
	get orderKey() {
		return this._order.OrderKey;
	}

	get orderNumber() {
		return this._order.OrderNumber;
	}

	get orderPackageKeys() {
		return this._order.Packages.map((p) => p.OrderPackageKey);
	}

	get orderPromos() {
		return this._order.OrderPromoCodes;
	}

	get orderTotal() {
		return this._order.Total;
	}

	get paymentData() {
		let nonce: string | undefined;
		return {
			Agreed: this.agreed,
			PaymentTypeKey: this.paymentDue?.PaymentTypeKey,
			PaymentMethodTypeKey: this.paymentDue?.PaymentMethodTypeKey,
			ProcessingTypeKey: this.paymentDue?.ProcessingTypeKey,
			Percentage: this.paymentDue?.Percentage,
			Amount: this.paymentDue?.Amount,
			OrderKey: this.orderKey,
			TenderedAmount: this.paymentDue?.TenderedAmount,
			CheckNumber: this.paymentDue?.CheckNumber,
			UUID: uuid.v4(),
			OrderPaymentKey: this.paymentDue?.OrderPaymentKey,
			ClientLocationKey: this._order.ClientLocationKey,
			PasswordClientLoginKey: clientModule.clientPassword,
			PasscodeClientLoginKey: clientModule.user?.ClientLoginKey,
			ReturnOrderObject: true,
			ShouldPrintReceipt: false,
			EmailReceipt: true,
			AutoPrintTickets: false,
		};
		return null;
	}

	get paidPayments() {
		return this._order.OrderPayments.filter((p) => p.Paid == true);
	}

	get subtotal() {
		if (this.isNew) {
			// Note: api does not calculate addons/tips into subtotal correctly until checkout() is called
			return this.items.reduce((p, c) => (p += c.Activities.reduce((q, d) => (q += d.SelectedTimes.reduce((r, e) => (r += e.SubTotal + e.AddOns.reduce((s, f) => (s += f.Cost * f.Quantity), 0)), 0)), 0)), 0);
		}
		return this._order.SubTotal;
	}

	async addBookPromo(promo: string): Promise<{ applied: boolean; message: string[] }> {
		this.error = null;
		const req = {
			OrderKey: this.orderKey,
			ClientLocationKey: clientModule.clientLocationKey,
			PromoCode: promo,
		} as PromoCodeModel;
		console.log(req);
		return apiService
			.post('AddPromoCode', req)
			.then((res) => {
				console.log(res);
				if (res.Order) this._order = res.Order;
				return {
					applied: res.Applied,
					message: res.ExceptionMessage ? ['There was an error applying this promo code! Please try again'] : res.ModelErrors?.map((e: { Key: string; Value: string[] }) => e.Value).flat() ?? [],
				};
			})
			.catch((e) => {
				console.log('ERROR', e);
				return {
					applied: false,
					message: ['Could not apply promo code to order.'],
				};
			});
	}
	async deleteBookPromo(promo: OrderPromoCode) {
		this.error = null;
		apiService.post('deletePromoCode', promo).then((res) => {
			if (res.Order) this._order = res.Order;
		});
	}

	async addPromo(promo: string): Promise<{ applied: boolean; message: string[] }> {
		this.error = null;
		return apiService
			.post('addPromoCode', {
				OrderKey: this.orderKey,
				ClientLocationKey: clientModule.clientLocationKey,
				PromoCode: promo,
				PasswordClientLoginKey: clientModule.clientPassword,
				PasscodeClientLoginKey: clientModule.user?.ClientLoginKey ?? '',
			} as PromoCodeModel)
			.then((res) => {
				if (res.Order) this._order = res.Order;
				return {
					applied: res.Applied,
					message: res.ExceptionMessage ? [res.ExceptionMessage] : res.ModelErrors?.map((e: { Key: string; Value: string[] }) => e.Value).flat() ?? [],
				};
			})
			.catch(() => {
				return {
					applied: false,
					message: ['Could not apply promo code to order.'],
				};
			});
	}

	async deletePromo(promo: OrderPromoCode) {
		this.error = null;
		apiService.post('deletePromoCode', promo).then((res) => {
			if (res.Order) this._order = res.Order;
		});
	}

	async saveCustomerToOrder(): Promise<any> {
		this.error = null;
		console.log(this.customerInfo);
		let data = {
			...this.customerInfo,
			passwordClientLoginKey: this._order.RequiresPasscodeToCheckout ? clientModule.clientPassword : '',
			passcodeClientLoginKey: this._order.RequiresPasscodeToCheckout ? clientModule.user?.ClientLoginKey ?? null : '',
		};
		//Massage data to appease API.
		data = JSON.parse(JSON.stringify(data));
		if (data.HeardAboutUsOther) {
			data.ClientLocationHeardAboutUsKey = null;
		}
		return apiService
			.post('saveCustomerToOrder', data)
			.then((res) => {
				if (res.Order) {
					if (res.Order.Customer && res.Order.Customer.HeardAboutUsOther) res.Order.Customer.ClientLocationHeardAboutUsKey = 0;
					this._order = res.Order;
				} else if (res.ModelErrors) {
					this.error = res.ModelErrors?.map((e: { Key: string; Value: string[] }) => e.Value)
						.flat()
						.join('<br>');
				}
				return res;
			})
			.catch((e) => {
				this.error = e;
				console.error('Could not save Payment', e);
				return e;
			});
	}
	//f4ddd75d-efbf-4507-abb4-05ff9a70836a
	async savePayment(params: { Response?: string; Nonce?: string; OrderPaymentKeyToCharge?: string }) {
		this.error = null;
		//payment is free, so just call checkout again
		// if (this.paid) {
		// 	return orderModule.continueCheckout();
		// }
		console.log(params);
		console.log({ ...this.paymentData, ...params });
		this.isLoading = true;
		if (this.paymentData) {
			console.log('Saving Payment');
			return await apiService
				.post('CompleteOrder', { ...this.paymentData, ...params })
				.then((res) => {
					if (res.Order) this._order = res.Order;
					else if (res.ModelErrors) {
						this.error =
							res.ModelErrors?.map((e: any) => e.Value)
								.flat()
								.join(', ') ?? [];
					}
					return res;
				})
				.catch((e) => {
					this.error = e;
					console.error('Could not save Payment', e);
					return null;
				})
				.finally(() => (this.isLoading = false));
		} else {
			console.log('No payment data');
		}
	}
	async updatePayment() {
		this.error = null;
		if (this.paymentData) {
			console.log('Updating Payment...');
			await apiService
				.post('updatePayment', this.paymentData)
				.then((res) => {
					if (res.Order && res.OrderPayment) {
						if (res.OrderPayment) this._order.OrderPayments = res.Order.OrderPayments;
						console.log('[OrderPayment]', this.paymentDue);
					}
				})
				.catch((e) => {
					console.error('Could not update Payment', e);
				});
		}
	}
	async updateQuantityCalculator(): Promise<void> {
		this.error = null;
		if (this.paymentDue)
			return await apiService
				.post('register/calculator', {
					Calculator: {
						...this.paymentDue.Calculator,
					},
				})
				.then((res) => {
					if (res.OrderPayment) this._order.OrderPayments = res.Order.OrderPayments;
				})
				.catch((e) => {
					console.error('Could not update quantity calculator', e);
				});
	}
	async abandonOrderChanges(): Promise<Order> {
		this.error = null;
		console.log('Abandoning Order Changes');
		return await apiService
			.get('AbandonOrderChanges', {
				orderKey: this.orderKey,
				clientLocationKey: this._order.ClientLocationKey,
				uuid: uuid.v4(),
			})
			.then((res: Order) => {
				if (res && res.Customer) {
					res.Customer = this._order.Customer;
					this._order = res;
					console.log('[Order]', this._order);
				}
				return res;
			});
	}

	async revertSplitPayment() {
		this.error = null;
		if (this.paymentDue) {
			console.log('Reverting Split Payment');
			// set all participant values to max to reset a split payment
			const payload = {
				Calculator: {
					...this.paymentDue.Calculator,
					OrderEntityTimes: this.paymentDue.Calculator.OrderEntityTimes.map((entityTime) => {
						return {
							...entityTime,
							Rates: entityTime.Rates.map((rate) => {
								return {
									...rate,
									Participants: rate.UnpaidParticipants,
									Discounts: rate.Discounts.map((discount) => {
										return {
											...discount,
											Participants: discount.UnpaidParticipants,
										};
									}),
								};
							}),
						};
					}),
				},
			};
			await apiService
				.post('register/calculator', payload)
				.then((res) => {
					if (res.OrderPayment) this._order.OrderPayments = res.Order.OrderPayments;
				})
				.catch((e) => {
					console.error('Could not revert split payment', e);
				});
		}
	}

	async getGiftCard(code: string): Promise<OrderMerchandiseGiftCard | null> {
		this.error = null;
		return await apiService
			.get('getGiftCard', {
				code,
				clientKey: clientModule.location?.ClientKey ?? -1,
			})
			.then((res) => {
				if (res.GiftCard) return res.GiftCard as OrderMerchandiseGiftCard;
				else if (res.ModelErrors) {
					this.error = res.ModelErrors.map((me: any) => me.Value).join(', ');
					return null;
				} else return null;
			})
			.catch((e: Error) => {
				console.error(`Could not get gift card ${e}`);
				return null;
			});
	}

	async redeemGiftCard(giftcardCode: string) {
		this.error = null;
		const payload = {
			GiftCardCode: giftcardCode,
			OrderKey: this.orderKey,
			ClientLocationKey: clientModule.clientLocationKey,
		};
		await apiService
			.post('redeemGiftCard', payload)
			.then((res) => {
				if (res.Order) this._order = res.Order;
				console.log(res);
			})
			.catch((e) => {
				console.error(`Error redeeming giftcard ${giftcardCode}`);
			});
	}

	// Returns a static property on order object
	getStaticProperty(field: string): any {
		this.error = null;
		return this._order[field as keyof Order] ?? undefined;
	}
}
