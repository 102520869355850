import { Platform } from '@/common/enums/platform.enum';
import { ClientLocation } from '@/common/models/ClientLocation.model';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { activityColorUtility } from '@/common/utilities/activityColors.utility';
// import { AllowedClient } from '@/common/models/AllowedClient.model';
// import { pusher } from '@/proxies/pusher.proxy';
import { apiService } from '@/services/api.service';
import { localStorageService } from '@/services/localStorage.service';
import store from '@/store/index';
import { add, format } from 'date-fns';
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules';
// import { scheduleModule } from '../schedule/schedule.vuex-module';
import { ClientEmployee } from '@/common/models/ClientEmployee.model';
import { LocationRequest } from './models/LocationRequest.model';
// import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { Client } from '@/common/models/Client.model';
import { Guide } from '@/common/models/Guide.model';

@Module({ generateMutationSetters: true })
class ClientModule extends VuexModule {
	// STATE
	private _client: Client | null = null;
	private _location: ClientLocation | null = null;
	private _user: ClientEmployee | null = null;
	private _userPlatform: Platform = Platform.DESKTOP;
	public _loading = false;
	private _cachedLocation: { clientKey: number; locationKey: number } | null = null;
	private _locationTimeZoneOffset = 0;
	private _locationTime = new Date();
	private _timeIterator!: number;

	//#region MUTATIONS
	@Mutation startLoading() {
		console.log('** Starting loading');
		this._loading = true;
	}

	@Mutation stopLoading() {
		console.log('** Stopping loading');
		this._loading = false;
	}

	@Mutation setUserPlatform(value: Platform) {
		this._userPlatform = value;
	}

	@Mutation setUser(value: ClientEmployee | null) {
		if (value) {
			this._user = value;
			localStorageService.set('user', value);
		} else {
			this._user = null;
			localStorageService.remove('user');
		}
	}

	@Mutation setClient(value: Client | null) {
		if (value) {
			this._client = value;
			localStorageService.set('client', value);
		} else {
			this._client = null;
			localStorageService.remove('client');
		}
	}

	@Mutation setLocation(location: ClientLocation | null) {
		activityColorUtility.clear();
		if (location) {
			// filter out activities w/o assigned color and assign an arbitrary one
			// while maintaining activity array order
			location.Activities?.filter((a) => !a.Color)
				.map((a) => {
					return a.EntityHierarchyKey;
				})
				.sort((a, b) => {
					return a > b ? 1 : -1;
				})
				.forEach((k) => activityColorUtility.assignColor(k));

			location.Activities?.forEach((activity: EntityHierarchy) => {
				if (activity.Color) activityColorUtility.addColor(activity.EntityHierarchyKey, activity.Color!);
			});

			this._location = location;
			localStorageService.set('location', location);
			// localStorageService.set('cachedLocation', {clientKey: this._client?.ClientKey, locationKey: location.ClientLocationKey});
			// this.updateLocationCache({ clientKey: this._client!.ClientKey!, locationKey: location.ClientLocationKey! });
		} else {
			this._location = null;
			localStorageService.remove('location');
			// localStorageService.remove('cachedLocation');
			// this.updateLocationCache(null);
		}
	}

	@Mutation setLocationCache(locKey: { clientKey: number; locationKey: number } | null) {
		if (locKey) {
			this._cachedLocation = locKey;
			localStorageService.set('cachedLocation', this._cachedLocation);
		} else {
			this._cachedLocation = null;
			localStorageService.remove('cachedLocation');
		}
	}

	@Mutation setLocationTimeZoneOffset(tz: string): void {
		try {
			// get UTC offset of client location
			const tSep = this._location!.UTCOffset!.indexOf('T') + 1;
			const hrOffset = this._location?.UTCOffset?.slice(tSep, tSep + 2);
			const utcOffset = -(24 - Number(hrOffset));

			// calculate hour in client location
			let hours = new Date().getUTCHours() + utcOffset;
			if (hours > 23) hours = 24 - hours;
			if (hours < 0) hours = 24 + hours;

			const dt = new Date(new Date().toLocaleString('en-US'));

			if (this._location?.ObserveDaylightSavingTime) {
				const jan = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset();
				const jul = new Date(new Date().getFullYear(), 6, 1).getTimezoneOffset();

				if (Math.max(jan, jul) !== dt.getTimezoneOffset()) {
					hours += 1; // add hour for daylight savings
					this._location.TimeZoneAbbr = this._location.TimeZoneAbbr?.replace('S', 'D'); // change timezone abbreviation
				}
			}

			this._locationTimeZoneOffset = hours - new Date().getHours();
		} catch {
			console.warn('Invalid client timezone');
			this._locationTimeZoneOffset = 0;
		}
	}

	//#endregion

	//#region GETTERS
	get isLoading() {
		return this._loading;
	}

	get desktopView(): boolean {
		return this.userPlatform == Platform.DESKTOP;
	}

	get user(): ClientEmployee | null {
		return this._user;
	}

	get availableLocations() {
		return this._user?.ClientLogin?.ClientLocations ? JSON.parse(JSON.stringify(this._user.ClientLogin.ClientLocations)) : [];
	}

	get entityHierarchyKeys() {
		return this._location?.Activities ? this._location?.Activities.map((item) => item.EntityHierarchyKey) : [];
	}

	get client() {
		return this._client;
	}

	get clientName(): string {
		return this._client?.ClientName ?? '';
	}

	get location() {
		return this._location;
	}

	get isAuthenticated() {
		// return this._client?.ClientKey === undefined;
		return this._client != undefined && this.user != undefined;
	}

	get clientKey(): number {
		return this._client?.ClientKey ?? -1;
	}

	get clientLocationKey() {
		return this._location?.ClientLocationKey ?? -1;
	}

	get clientEmployeeKey(): number {
		return this._user?.ClientEmployeeKey ?? -1;
	}

	get clientPassword(): string {
		return this._user?.ClientLoginKey ?? '';
	}

	get userPlatform() {
		return this._userPlatform;
	}

	get locationActivities(): EntityHierarchy[] {
		return this._location?.Activities?.length ? this._location.Activities : [];
	}

	get locationTimezoneOffset(): number {
		return this._locationTimeZoneOffset;
	}

	get disablePasscodes() {
		return this._location?.TurnOffPasscodes ?? false;
	}

	get heardAboutUsOptions() {
		return this._location?.HeardAboutUs ?? [];
	}

	get organizationTypeOptions() {
		return this._location?.OrganizationTypes ?? [];
	}

	get countryOptions() {
		return this._location?.Countries ?? [];
	}

	get guides(): { displayName: string; clientEmployeeKey: number }[] {
		return (
			this._location?.Guides.map((g: Guide) => {
				return {
					displayName: g.Nickname ? g.Nickname.trim() : `${g.FirstName.trim()} ${g.LastName.trim().slice(0, 1)}`,
					clientEmployeeKey: g.ClientEmployeeKey,
				};
			}) ?? []
		);
	}

	get locationDiscounts() {
		return this._location?.ClientLocationDiscounts ?? [];
	}

	get locationTimeStr() {
		return format(this._locationTime, `h:mm:ss a @, ccc MMM d`).replace('@', `${this.location?.TimeZoneAbbr ?? ''}`);
	}

	get locationTime() {
		return this._locationTime;
	}

	//#endregion

	//#region ACTIONS
	@Action async initializeClient(): Promise<void> {
		this.startLoading();
		console.info('Initializing Client Module');

		// refresh time when user returns focus to page
		document.addEventListener('visibilitychange', () => {
			if (!document.hidden) {
				clearInterval(this._timeIterator);
				this._locationTime = this.calcLocationTime();
				this._timeIterator = this.getTimeIterator();
			}
		});

		this.setClient(localStorageService.get('client'));
		const cachedLocation = localStorageService.get('cachedLocation');
		if (this._client?.ClientKey == cachedLocation?.clientKey) {
			this.setLocationCache(cachedLocation);
		}
		this.detectUserPlatform();

		//TODO - REMOVE [getClientLocation] CALL ONCE SKINNY CLIENTLOCATION- API IS COMPLETE
		await this.getUserData().then(async (locKey) => {
			await this.updateClientLocation(locKey);
		});

		// pusher.subscribe(`${this.clientLocationKey}_channel`);
	}

	/**
	 * Gets employee and client location data objects
	 */
	@Action async getUserData(): Promise<number> {
		return await apiService.post('register/login', { clientKey: this.client?.ClientKey, clientLocationKey: this._cachedLocation?.locationKey ?? null }).then((res: any) => {
			if (res.IsValid) {
				// this.setLocation(res.ClientLocation as ClientLocation);
				this.setUser(res.ClientEmployee as ClientEmployee);
				return res.ClientLocation.ClientLocationKey;
			} else {
				console.error('Could not get client user data.');
				return -1;
			}
		});
	}

	/**
	 * Detects if user screen orientation
	 */
	@Action detectUserPlatform(): void {
		if (window.innerWidth > window.innerHeight && window.innerWidth > 1024) this.setUserPlatform(Platform.DESKTOP);
		else this.setUserPlatform(Platform.MOBILE);
	}

	@Action async updateClientLocation(clientLocationKey: number): Promise<boolean> {
		this.startLoading();
		clearInterval(this._timeIterator);

		const locationRequest: LocationRequest = {
			includeProjects: false,
			ClientLocationKey: clientLocationKey,
		};
		return await apiService
			.get('getclientlocation', locationRequest)
			.then((responseData) => {
				if (responseData) {
					this.setLocation(responseData);
					this.setLocationTimeZoneOffset(responseData.TimeZoneAbbr ?? '');
					this.setLocationCache({ clientKey: this.clientKey, locationKey: this.clientLocationKey });

					if (this._location?.TimeZoneAbbr) {
						this._locationTime = this.calcLocationTime();
						this._timeIterator = this.getTimeIterator();
					}

					return true;
				}
				return false;
			})
			.finally(() => this.stopLoading());
	}

	@Action async logout(): Promise<boolean> {
		// this.startLoading();
		// scheduleModule.clearScheduleModule();
		this.setClient(null);
		this.setLocation(null);
		this.setUser(null);
		this.setLocationCache(null);
		return localStorageService.get('userClients') ?? false;
	}

	private getTimeIterator(): number {
		return window.setInterval(() => {
			this._locationTime = add(this._locationTime, { seconds: 1 });
		}, 1000);
	}

	private calcLocationTime() {
		return add(new Date(), {
			hours: this.locationTimezoneOffset,
		});
	}

	//#endregion
}

export const clientModule = new ClientModule({ store, name: 'clientModule' });
