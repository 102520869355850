
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'Currency',
})
export default class Currency extends Vue {
	@Prop() amount!: number;

	get formatted(): string {
		var str = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(this.amount);
		str.replace('.00', '');
		return str;
	}
}
