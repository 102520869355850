// utility class that returns activity color based on entity key
class ActivityColorUtility {
	colorSwatch = ['#FA3232', '#FFC0CB', '#A00000', '#FFA500', '#FFDA93', '#228B22', '#00F8E5', '#80CBC4', '#8BC5FF', '#0000FF', '#004991', '#9400FF', '#D293FF', '#FF4EFF', '#BE6646'];
	defaultColor = '#ccc';
	colorDict: Map<number, string> = new Map();

	addColor(key: number, color: string) {
		this.colorDict.set(key, color);
	}

	clear() {
		this.colorDict.clear();
	}

	assignColor(k: number): void {
		this.addColor(k, this.colorSwatch[this.colorDict.size]);
	}
	getColor(key: number): string | null {
		if (this.colorDict.has(key)) return this.colorDict.get(key)!;
		return null;
	}

	getRandomColor(): string {
		return this.colorSwatch[Math.floor(Math.random() * this.colorSwatch.length)];
	}
}

export const activityColorUtility = new ActivityColorUtility();
